import * as React from "react"

const TeamServiceBlock = () => {
  return (
    <>
      <div className="w-full lg:w-1/2">
                    <div className="p-16 bg-primary-50">
                        <h2 className="mb-4">A team you can trust</h2>
                        <ul className="text-2xl list-disc pl-6">
                            <li className="mb-4">Part of a major clean technology services conglomerate serving clients worldwide</li>
                            <li className="mb-4">Experts in the delivery of a wide range of rooftop PV plants and solar parks</li>
                            <li className="mb-4">Experienced in building the largest solar car parking projects in the UK</li>
                        </ul>
                    </div>
                </div>

                <div className="w-full lg:w-1/2 pt-16">
                    <h2 className="mb-4">A complete service</h2>
                    <p>From consultation and design through to commissioning and maintenance, we can help with every step you need to be able to generate your own clean electricity for electric vehicle charging and solar self-consumption. </p>
                    <h3 className="mt-6 mb-4">Benefit from:</h3>
                    <ul className="list-disc pl-6">
                        <li>Improved environmental credentials</li>
                        <li>A more secure energy supply</li>
                        <li>Independence from the grid</li>
                        <li>Lower carbon emissions</li>
                        <li>New revenue streams</li>
                        <li>Reduced energy bills</li>
                    </ul>
                </div>

    </>
  )
}
export default TeamServiceBlock
