import * as React from "react"

import ButtonPrimaryArrow from "components/buttons/ButtonPrimaryArrow"

const ContactCTABlock = () => {
  return (
    <div className="container text-center">
      <p className="text-3xl md:text-4xl mb-8 font-light leading-relaxed md:leading-relaxed">Contact us now for a no-obligation consultation on the potential of solar for your business. We will help you build a business plan for investment and work with you to find the best financing and ownership model for your organisation.</p>
      <p className="flex justify-center"><ButtonPrimaryArrow title="Contact Us" url="/contact" /></p>
    </div>
  )
}
export default ContactCTABlock
