import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

import ImgBackground from "components/heroes/ImgBackground"
import TitleBox from "components/titles/Titlebox"
import { StaticImage } from "gatsby-plugin-image"

import TeamServiceBlock from "../components/blocks/TeamService"
import ContactCTABlock from "../components/blocks/ContactCTA"

const EVChargePage = () => {

  // using static header image, load here
  const data = useStaticQuery(
    graphql`
    query {
      heroImage: file(relativePath: { eq: "general/pv-ev-charging.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
          )
        }
      }
      sectionImage: file(relativePath: { eq: "illustrations/car-ev-charge.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
          )
        }
      }
    }
  `
  )


  return (
    <Layout>
      <Seo title="Page" />

      {/* Title banner */}
      <ImgBackground image={data.heroImage}>
        <div className="container m-auto py-10 lg:pb-0 md:pt-40 lg:pt-60 lg:flex">
          <TitleBox
            title="Prepare for vehicle electrification"
            caption="Products"
          >
            <p className="text-2xl text-white font-normal">Countries around the world are banning the sale of fossil-fuelled vehicles in the years to come. That means your customers and employees will increasingly travel in EVs. Keep them moving with a solar car park canopy that incorporates EV charging.</p>
          </TitleBox>
        </div>
      </ImgBackground>


      {/* Content Area */}
      <section className="page--content py-16 lg:py-32 lg:pb-16">
        <div className="container lg:flex lg:space-x-12">

          <div className="content-area mb-12 lg:w-1/2">
            <p className="text-leader">We can help you integrate solar charging stations into your car park facilities, giving you clean energy to power your organisation and its vehicles.</p>
            <p>Solar car parks can help tackle pollution and carbon emissions while adding to national charging infrastructure, so local planning authorities are likely to look at your project with sympathetic eyes.</p>
            <p>Similarly, most landlords should be pleased to see a solar car park because it improves the value of their assets. In any case, we’ll help with your planning application so that you have the best chances of success.</p>
            <p>And we’ll be happy to discuss the benefits with your landlord and help you to gain approval.</p>                    </div>

          <div className="image-quote lg:w-1/2">
            <StaticImage src="../images/backgrounds/ev-charge-bk.jpg" width="600" height="300" alt="utility scale solar" className="rounded-xl rounded-tl-none" />
            <div className="p-8 bg-primary text-white rounded-xl text-3xl font-light max-w-lg relative float-right -mt-8 z-2 mb-8">Solar car parks can help tackle pollution and carbon emissions while adding to national charging infrastructure...</div>
            <div className="clear-both"></div>
          </div>

        </div>
      </section>

      {/* concept charger panel */}
      <section className="bg-primary-50 relative">
        <div className="ev-bk-shape absolute bottom-0 right-0"></div>
        <div className="container lg:flex">
          <div className="lg:w-3/4">
            <div className="p-8 md:p-16 lg:p-32 md:pl-0 lg:pl-0 text-1xl lg:text-1xl xl:text-2xl font-light max-w-4xl justify-items-end">There are many benefits to investing in solar and even if your organisation does not have access to the capital to fund a project then we can offer a range of alternative funding mechanisms, such as delivering electricity through a power purchase agreement. Speak to us about the options.<br /><br />
              From consultation and design through to commissioning and maintenance, we can help with every step you need to be able to generate your own clean electricity for electric vehicle charging and solar self-consumption.</div>
          </div>
          <div className="lg:w-1/4">
            <div className="flex justify-center">
              <StaticImage src="../images/illustrations/PGT-EV-Charger.png" alt="PGT Concept Charger Option" className="-mt-8" />
            </div>
          </div>
        </div>
      </section>

      {/* EV forecourt */}
      <section className="py-32 pb-0">
        <div className="container">
          <h2 className="mb-4">Develop EV stations and forecourts</h2>
          <p className="text-leader-lg mb-8">The next few years will bring major opportunities for builders of EV charging networks. As electric vehicle charging times decrease, closer to those of a traditional refuelling experience, EV charging stations will be a key smart low-carbon infrastructure asset.</p>

          <div className="lg:flex lg:space-x-12">
            <div className="content-area mb-12 lg:w-1/2  lg:pr-8">
              <p>Pacific Green has been part of this clean energy revolution from the beginning and has worldwide experience in providing power and energy storage solutions. Our highly efficient charging stations are aesthetically pleasing and offer customer-friendly facilities. </p>
              <p>They come in several configurations, from a small autonomous operation through to larger forecourts incorporating rest and retail units. </p>
              <p>These scalable, flexible and expandible systems can be configured to suit even the tightest project location, combining integrated solar canopies with EV charging, refreshment, retail and drive-through facilities.</p>
            </div>

            <div className="image-quote lg:w-1/2">
              <StaticImage src="../images/general/retail-pv+ev-station.jpg" width="600" height="300" alt="utility scale solar" className="rounded-xl rounded-tl-none" />
              <div className="p-8 bg-primary text-white rounded-xl text-2xl font-light max-w-lg relative float-right -mt-8 z-2 mb-8">Our prefabricated, modular designs allow rapid installation, competitive pricing and the ability to incorporate future improvements, allowing you to scale operations quickly and easily. </div>
              <div className="clear-both"></div>
            </div>
          </div>
        </div>
      </section>


      {/* forecourt plans */}
      <section className="py-32 pb-0">
        <div className="container">
          <h2 className="mb-4">Plan, evaluate, install...</h2>
          <p className="text-leader-lg mb-8">We will help with planning, power evaluation, licensing, operator management and, of course, professional installation within a complete turnkey service. As part of a premium user experience, your branding can appear at all customer contact points throughout the charging platform.</p>

          <div className="lg:flex lg:space-x-12">
            <div className="content-area mb-12 lg:w-1/4">
              <p>We can seamlessly take care of every aspect of forecourt design and development, including the customer cloud platform, electricity supply groundwork, islands, customer rest areas and signage.</p>
            </div>

            <div className="lg:w-3/4">
              <StaticImage src="../images/illustrations/architects-example-large.png" alt="example architectual plan" className="" />
            </div>
          </div>
        </div>
      </section>

      {/* team service block */}
      <section className="container lg:flex lg:space-x-32 py-16 lg:py-32">
        <TeamServiceBlock />
      </section>

      {/* CTA contact block */}
      <section className="py-16 lg:py-32">
        <ContactCTABlock />
      </section>

    </Layout>
  )
}

export default EVChargePage
